import { createSlice } from "@reduxjs/toolkit";
import {
  getKPIList,
  getOperationalList,
  getProjectList,
  getSiteList,
} from "./kpiThunk";

const initialState = {
  kpiList: [],
  listProject: [],
  listSites: [],
  listOperational: [],
  loading: true,
};

const kpiSlice = createSlice({
  initialState,
  name: "kpi",
  extraReducers: (builder) => {
    builder.addCase(getKPIList.pending, (state, { payload }) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getKPIList.fulfilled, (state, { payload }) => {
      return {
        ...state,
        loading: false,
        kpiList: payload?.result || [],
      };
    });
    builder.addCase(getKPIList.rejected, (state, { payload }) => {
      return {
        ...state,
        loading: false,
      };
    });

    builder.addCase(getProjectList.pending, (state, { payload }) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getProjectList.fulfilled, (state, { payload }) => {
      return {
        ...state,
        loading: false,
        listProject: payload?.result || [],
      };
    });
    builder.addCase(getProjectList.rejected, (state, { payload }) => {
      return {
        ...state,
        loading: false,
      };
    });

    builder.addCase(getSiteList.pending, (state, { payload }) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getSiteList.fulfilled, (state, { payload }) => {
      return {
        ...state,
        loading: false,
        listSites: payload?.result || [],
      };
    });
    builder.addCase(getSiteList.rejected, (state, { payload }) => {
      return {
        ...state,
        loading: false,
      };
    });

    builder.addCase(getOperationalList.pending, (state, { payload }) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getOperationalList.fulfilled, (state, { payload }) => {
      return {
        ...state,
        loading: false,
        listOperational: payload?.result || [],
      };
    });
    builder.addCase(getOperationalList.rejected, (state, { payload }) => {
      return {
        ...state,
        loading: false,
      };
    });
  },
});

export default kpiSlice.reducer;
