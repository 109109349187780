export const MAIN_WRAPPER_CLASSES =
  "bg-white rounded-md p-3 px-7 shadow-2xl h-[calc(100vh-100px)] w-full dark:bg-slate-700 dark:text-white overflow-y-auto";
export const GRADIENT_BACKGROUND =
  "bg-gradient-to-r from-[#8c78d3] to-[#8c78d3ba]";
export const BUTTON_HOVER_EFFECT =
  "hover:bg-[#8c78d3] dark:hover:bg-[#8c78d3] hover:border-[#8c78d3] hover:text-white text-slate-800";
export const DEFAULT_FORM_INPUT_FIELD =
  "bg-gray-50 autofill:bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-slate-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:outline-0 dark:focus:ring-blue-500 dark:focus:border-blue-500 max-sm:w-full";
export const DEFAULT_FORM_LABEL =
  "block mb-2 text-sm font-medium text-gray-900 dark:text-white";
export const DEFAULT_ICON_BUTTON =
  "w-full px-4 py-2 font-medium text-left rtl:text-right text-white bg-blue-700 border-b border-gray-200 rounded-t-lg cursor-pointer focus:outline-none dark:bg-gray-800 dark:border-gray-600";
export const DEFAULT_TOGGLE_BUTTON =
  "duration-150 w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-slate-600 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#8c78d3]";
export const DEFAULT_MENU_LABEL_STEPPER =
  "flex items-center justify-center w-5 h-5 text-xs border rounded-full shrink-0";
export const DEFAULT_RADIO_LABELS =
  "w-4 h-4 text-blue-600 bg-gray-50 border-gray-300 dark:bg-slate-800 dark:border-gray-600 dark:text-white";
export const DEFAULT_FILE_UPLOAD_INPUT =
  "block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400";
