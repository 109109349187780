import { Navigate } from "react-router-dom";
import getRoute from "../routes/utils/getRoute";
import routesConstants from "../routes/routesConstants";

const RedirectToHome = () => {
  return (
    <Navigate
      to={getRoute({
        routes: routesConstants.KPI,
      })}
      replace
    />
  );
};

export default RedirectToHome;
