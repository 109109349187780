import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";

const Layout = () => {
  const { sidebarEnabled } = useSelector((state) => state.common);

  return (
    <>
      {/* <ThemeSetting /> */}
      <div className={`${!sidebarEnabled ? "wrap-toggle" : "no-wraptoggle"}`}>
        <Header />
        <div
          className={`${
            !sidebarEnabled ? "flex max-md:flex-col" : ""
          } main-wrap dark:bg-slate-600`}
        >
          <Sidebar />
          {sidebarEnabled ? (
            <div className={`${!sidebarEnabled ? "pl-32 w-full" : ""}`}>
              <div
                style={{ height: "calc(100vh - 57px)" }}
                className="flex flex-wrap w-full content-wrap md:pl-48 max-sm:pl-6 pe-5 bg-gray-200/30 dark:bg-slate-800"
              >
                <Outlet />
              </div>
            </div>
          ) : (
            <div
              style={{ height: "calc(100vh - 57px)" }}
              className="flex flex-wrap w-full content-wrap lg:pl-28 pe-5 bg-gray-200/30 dark:bg-slate-800"
            >
              <Outlet />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Layout;
