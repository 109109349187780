import React from "react";
import { Navigate } from "react-router-dom";
import routesConstants from "../routes/routesConstants";
import getRoute from "../routes/utils/getRoute";

const RedirectToLogin = () => {
  return (
    <Navigate
      to={getRoute({ routes: [routesConstants.AUTHENTICATION_PAGE] })}
      replace
    />
  );
};

export default RedirectToLogin;
