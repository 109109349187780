import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { login } from "./authThunk";
import LocalStorage from "../../../services/localStorage";
import Cookies, { cookieKeys } from "../../../services/cookies";

const initToken = () => Cookies.get(cookieKeys.TOKEN) || false;
const initRole = () => LocalStorage.get("employeeRole") ?? null;

const initialState = () => ({
  token: initToken() || "",
  isAuth: !!initToken(),
  //for later use
  themeMode: 0,
  themeOption: 0,
  custom_theme_color: "#1f74df",
  employeeDetail: {},
  userRole: initRole(),
});

const authSlice = createSlice({
  initialState: initialState(),
  name: "auth",
  reducers: {
    setThemeMode: (state, action) => ({
      ...state,
      themeMode: action.payload,
    }),
    setThemeOption: (state, action) => ({
      ...state,
      themeOption: action.payload,
    }),
    setThemeColor: (state, action) => ({
      ...state,
      custom_theme_color: action.payload,
    }),
    logout: (state, { payload }) => {
      Cookies.clear();
      LocalStorage.clear();
      state = initialState();
      window.location.reload();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        Cookies.set(cookieKeys.TOKEN, payload.result?.access);
        Cookies.set(cookieKeys.REFRESH_TOKEN, payload.result?.refresh);
        state.token = payload?.result?.access;
        state.isAuth = true;
        toast.success(payload?.message || "Login successful");
      })
      .addCase(login.rejected, (state, { payload }) => {
        toast.error(
          +payload.code === 401
            ? "Please provide valid credentials."
            : payload?.message || "Something went wrong"
        );
      });
    // builder.addCase(fetchEmployeeDetail.fulfilled, (state, { payload }) => ({
    //   ...state,
    //   employeeDetail: payload,
    //   userRole: payload[0]?.user_details?.is_superuser ? 0 : 1,
    //   themeMode: payload[0].theme_mode,
    //   themeOption: payload[0].theme,
    //   custom_theme_color: payload[0].custom_theme_color,
    // }));
  },
});

// export const fetchEmployeeDetail = createAsyncThunk(
//   "auth/empDetail",
//   async (payload, thunk) => {
//     try {
//       const res = await axiosAPI.get(FETCH_EMPLOYEE_DETAIL, {
//         params: {
//           user_profile: 1,
//         },
//       });
//       if (res.data.result) {
//         localStorage.setItem(
//           "employeeRole",
//           res.data.result[0]?.user_details?.is_superuser ? 0 : 1,
//         );
//       }
//       return res.data.result;
//     } catch (e) {
//       return thunk.rejectWithValue(e?.response?.data);
//     }
//   },
// );

export const { logout, setThemeMode, setThemeOption, setThemeColor } =
  authSlice.actions;

export default authSlice.reducer;
