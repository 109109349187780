import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAPI } from "../../../services/api";
import { GET_MONITORING_LOGS } from "../../../constants/url_helpers";

export const getIncidentList = createAsyncThunk(
  "kpi/getIncidentList",
  async (payload, thunk) => {
    try {
      const res = await axiosAPI.get(GET_MONITORING_LOGS);
      let json = await res.data;
      return json;
    } catch (e) {
      return thunk.rejectWithValue(e?.response?.data);
    }
  }
);
