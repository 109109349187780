import { createAsyncThunk } from "@reduxjs/toolkit";

import { LOGIN } from "../../../constants/url_helpers";
import { axiosAPI } from "../../../services/api";

export const login = createAsyncThunk("auth/login", async (payload, thunk) => {
  try {
    const res = await axiosAPI.post(LOGIN, payload);
    let json = await res.data;
    return json;
  } catch (e) {
    return thunk.rejectWithValue(e?.response?.data);
  }
});
