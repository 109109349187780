import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_KPI_LIST,
  GET_MONITORING_OPERATIONAL,
  GET_MONITORING_PROJECT,
  GET_MONITORING_SITE,
} from "../../../constants/url_helpers";
import { axiosAPI } from "../../../services/api";

export const getKPIList = createAsyncThunk(
  "kpi/getKPIList",
  async (payload, thunk) => {
    try {
      const res = await axiosAPI.get(GET_KPI_LIST, {
        params: {
          ...payload,
        },
      });
      let json = await res.data;
      return json;
    } catch (e) {
      return thunk.rejectWithValue(e?.response?.data);
    }
  }
);

export const getProjectList = createAsyncThunk(
  "kpi/getProjectList",
  async (payload, thunk) => {
    try {
      const res = await axiosAPI.get(GET_MONITORING_PROJECT);
      let json = await res.data;
      return json;
    } catch (e) {
      return thunk.rejectWithValue(e?.response?.data);
    }
  }
);

export const getSiteList = createAsyncThunk(
  "kpi/getSiteList",
  async (payload, thunk) => {
    try {
      const res = await axiosAPI.get(GET_MONITORING_SITE, {
        params: {
          ...payload,
        },
      });
      let json = await res.data;
      return json;
    } catch (e) {
      return thunk.rejectWithValue(e?.response?.data);
    }
  }
);

export const getOperationalList = createAsyncThunk(
  "kpi/getOperationalList",
  async (payload, thunk) => {
    try {
      const res = await axiosAPI.get(GET_MONITORING_OPERATIONAL);
      let json = await res.data;
      return json;
    } catch (e) {
      return thunk.rejectWithValue(e?.response?.data);
    }
  }
);
