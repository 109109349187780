import React, { useEffect, useMemo } from "react";
import { kpiLogo, logoVexillum } from "../constants/images";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CiCircleList, CiTextAlignCenter } from "react-icons/ci";
import { StylishhTooltip } from "stylishh-tooltip";
import { sidebarMenus } from "../constants";
import LocalStorage from "../services/localStorage";
import { setSidebarState, setThemeMode } from "../store/commonSlice";
import { searchMenu } from "../utils";

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { themeMode, sidebarEnabled } = useSelector((state) => state.common);

  const toggleSidebar = () => {
    dispatch(setSidebarState(!sidebarEnabled));
  };

  useEffect(() => {
    LocalStorage.set("themeModeRepo", themeMode);
    if (
      themeMode === 1 ||
      (!("themeModeRepo" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.querySelector("html").classList.add("dark");
    } else {
      document.querySelector("html").classList.remove("dark");
    }
  }, [themeMode]);

  const findHeaderLabel = useMemo(() => {
    return searchMenu(sidebarMenus, location.pathname)?.label;
  }, [location]);

  return (
    <header className="border-t-2 border-solid border-[#1d8cf8] bg-slate-50 dark:text-white dark:bg-slate-800 flex items-center z-10 sticky top-0 py-0 px-4 justify-between">
      <div className="flex items-center z-10 relative dark:text-white w-full gap-2 max-w-56 h-14 pt-1 pr-7 pl-7">
        {sidebarEnabled ? (
          <CiTextAlignCenter
            onClick={toggleSidebar}
            className="cursor-pointer h4 mb-0 text-xl"
          />
        ) : (
          <CiCircleList
            onClick={toggleSidebar}
            className="pointer h4 mb-0 text-xl"
          />
        )}

        <img
          alt="logo"
          width={50}
          src={kpiLogo}
          className="bg-cover bg-center max-sm:hidden h-auto ms-5"
        />
        <p className="mb-0 uppercase font-light hidden max-sm:flex">
          {findHeaderLabel}
        </p>
      </div>
      {/* <div className="toggle-header-section hidden"></div> */}
      <div className="flex items-center gap-2">
        <div className="flex flex-row justify-center ml-3">
          <input
            type="checkbox"
            id="light-switch"
            name="light-switch"
            className="light-switch hidden"
            onClick={(e) => {
              dispatch(setThemeMode(+e.target.checked));
            }}
          />
          <StylishhTooltip
            id="light-switch-label"
            styledClass="top-11 py-1 px-2 dark:bg-white dark:text-black"
          >
            Toggle Theme
          </StylishhTooltip>
          <label
            className="relative cursor-pointer p-2"
            id="light-switch-label"
            htmlFor="light-switch"
          >
            <svg
              className="dark:hidden"
              width="16"
              height="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="fill-slate-300"
                d="M7 0h2v2H7zM12.88 1.637l1.414 1.415-1.415 1.413-1.413-1.414zM14 7h2v2h-2zM12.95 14.433l-1.414-1.413 1.413-1.415 1.415 1.414zM7 14h2v2H7zM2.98 14.364l-1.413-1.415 1.414-1.414 1.414 1.415zM0 7h2v2H0zM3.05 1.706 4.463 3.12 3.05 4.535 1.636 3.12z"
              />
              <path
                className="fill-slate-400"
                d="M8 4C5.8 4 4 5.8 4 8s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4Z"
              />
            </svg>
            <svg
              className="hidden dark:block"
              width="16"
              height="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="fill-slate-400"
                d="M6.2 1C3.2 1.8 1 4.6 1 7.9 1 11.8 4.2 15 8.1 15c3.3 0 6-2.2 6.9-5.2C9.7 11.2 4.8 6.3 6.2 1Z"
              />
              <path
                className="fill-slate-500"
                d="M12.5 5a.625.625 0 0 1-.625-.625 1.252 1.252 0 0 0-1.25-1.25.625.625 0 1 1 0-1.25 1.252 1.252 0 0 0 1.25-1.25.625.625 0 1 1 1.25 0c.001.69.56 1.249 1.25 1.25a.625.625 0 1 1 0 1.25c-.69.001-1.249.56-1.25 1.25A.625.625 0 0 1 12.5 5Z"
              />
            </svg>
            <span className="sr-only">Switch to light / dark version</span>
          </label>
        </div>
        {/* <p
          className="cursor-pointer"
          onClick={() => {
            dispatch(logout());
          }}
        >
          Logout
        </p> */}
        <img src={logoVexillum} alt="logo" className="h-12 max-sm:hidden" />
      </div>
    </header>
  );
};

export default Header;
