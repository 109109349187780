import { createSlice } from "@reduxjs/toolkit";
//theme 0 : light, 1: dark
const initialState = {
  sidebarEnabled: true,
  activePage: "dashboard-files",
  themeMode: 0,
  employeeList: {},
  employeeLoading: true,
  currentEmployee: {},
  browseBaseList: { data: [], loading: false },
  subFolderData: { data: [], loading: false },
};

const commonSlice = createSlice({
  initialState,
  name: "common",
  reducers: {
    setSidebarState: (state, action) => ({
      ...state,
      sidebarEnabled: action.payload,
    }),
    setActivePage: (state, action) => ({
      ...state,
      activePage: action.payload,
    }),
    setThemeMode: (state, action) => ({
      ...state,
      themeMode: action.payload,
    }),
  },
  extraReducers: (builder) => {},
});

export const { setSidebarState, setActivePage, setThemeMode } =
  commonSlice.actions;
export default commonSlice.reducer;
