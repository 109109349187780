import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-datetime/css/react-datetime.css";
import Routes from "./routes";

function App() {
  return (
    <>
      <div className="font-poppins bg-white min-h-screen">
        <Routes />
      </div>
      <ToastContainer autoClose={5000} />
    </>
  );
}

export default App;
