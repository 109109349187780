import RedirectToLogin from "../utils/RedirectToLogin";
import routesConstants from "./routesConstants";
import { AuthenticationPage, IncidentPage, KPIPage } from "./routesImport";

const routesConfig = {
  private: [
    {
      path: routesConstants.KPI,
      Component: KPIPage,
      permission: false,
    },
    {
      path: routesConstants.INCIDENT,
      Component: IncidentPage,
      permission: false,
    },
  ],
  public: [
    {
      path: "/",
      Component: RedirectToLogin,
    },
    {
      path: routesConstants.AUTHENTICATION_PAGE,
      Component: AuthenticationPage,
    },
  ],
};

export default routesConfig;
