
export const Loader = () => {
  return (
    <div className={"loader flex justify-center h-[calc(100vh-200px)] items-center w-full"}>
      <div className="loading inline-block relative w-16 h-16">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
