import { configureStore } from "@reduxjs/toolkit";
import commonSlice from "./commonSlice";
import authSlice from "../modules/Authentication/features/authSlice";
import kpiSlice from "../modules/KPI/features/kpiSlice";
import incidentSlice from "../modules/Incident/features/incidentSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    common: commonSlice,
    kpi: kpiSlice,
    incident: incidentSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});

export default store;
