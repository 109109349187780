import axios from "axios";
import { toast } from "react-toastify";

import LocalStorage from "./localStorage";
import SessionStorage from "./sessionStorage";
import Cookies, { cookieKeys } from "./cookies";
import routesConstants from "../routes/routesConstants";

export const API_URL = process.env.REACT_APP_API;

class Axios {
  constructor(baseURL) {
    this.axios = axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use(this._requestMiddleware);

    this.axios.interceptors.response.use(
      this._responseMiddleware,
      this._responseErr
    );
  }

  _requestMiddleware = (req) => {
    // Send Auth token on every request
    const token = Cookies.get(cookieKeys.TOKEN);
    if (!!token) {
      req.headers.Authorization = "Bearer " + token;
    }

    return req;
  };

  _responseMiddleware = (response) => {
    //  Do something on every success full response

    return response;
  };

  _responseErr = (error) => {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== "/" + routesConstants.LOGIN
    ) {
      if (
        Cookies.get(cookieKeys.TOKEN) === Cookies.get(cookieKeys.REFRESH_TOKEN)
      ) {
        Cookies.clear();
        LocalStorage.clear();
        SessionStorage.clear();
        // window.location.replace("/");
        return Promise.reject(error);
      } else {
        Cookies.set(cookieKeys.TOKEN, Cookies.get(cookieKeys.REFRESH_TOKEN));
        return Promise.reject(error);
      }
      // Logout / Redirect

      //Clear required local information
    }

    if (error?.response?.status === 429) {
      toast.error(
        error?.response?.data?.message ||
          "Too many requests. Please try again after sometime."
      );
    }

    return Promise.reject(error);
  };
}

const axiosAPI = new Axios(API_URL).axios;

export { axiosAPI };
