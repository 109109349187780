import lazy from "../utils/lazy";

export const AuthenticationPage = lazy(() =>
  import(
    /* webpackChunkName: "AuthenticationPage" */ "../modules/Authentication"
  )
);
export const KPIPage = lazy(() =>
  import(/* webpackChunkName: "KPI" */ "../modules/KPI")
);
export const IncidentPage = lazy(() =>
  import(/* webpackChunkName: "Incident" */ "../modules/Incident")
);
