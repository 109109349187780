import { createSlice } from "@reduxjs/toolkit";
import { getIncidentList } from "./incidentThunk";

const initialState = {
  incidentList: [],
};

const incidentSlice = createSlice({
  initialState,
  name: "incident",
  extraReducers: (builder) => {
    builder.addCase(getIncidentList.pending, (state, { payload }) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getIncidentList.fulfilled, (state, { payload }) => {
      return {
        ...state,
        loading: false,
        incidentList: payload || [],
      };
    });
    builder.addCase(getIncidentList.rejected, (state, { payload }) => {
      return {
        ...state,
        loading: false,
      };
    });
  },
});

export default incidentSlice.reducer;
