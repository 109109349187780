import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Routes as ReactRouterRoutes, Route } from "react-router-dom";
import RedirectToHome from "../utils/RedirectToHome";
import RedirectToLogin from "../utils/RedirectToLogin";
import routesConfig from "./routes.config";
import { Loader } from "../components/Loader";
import Layout from "../layout";

const Public = (route) => {
  const { Component } = route;

  return (
    <Suspense fallback={<Loader />}>
      <Component />
    </Suspense>
  );
};

const Private = (route) => {
  const { Component } = route;

  return (
    <Suspense fallback={<Loader />}>
      <Component />
    </Suspense>
  );
};

const createNestedRoutes = (routes, RouteType, user) => {
  return routes.map((route, i) => {
    if (!route.Component) {
      throw new Error("Component is required....");
    }
    if (route.children) {
      return (
        <Route path={route.path} key={i} element={<RouteType {...route} />}>
          {createNestedRoutes(route.children, RouteType, user)}
        </Route>
      );
    } else {
      if (route?.permission && route?.permission !== user) return null;
      else {
        return (
          <Route
            key={i}
            index={route.index}
            path={route.path}
            element={<RouteType {...route} />}
          />
        );
      }
    }
  });
};

const Routes = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { currentEmployee } = useSelector((state) => state.common);

  const { private: privateRoutes, public: publicRoutes } = routesConfig;

  return (
    <ReactRouterRoutes>
      {isAuth ? (
        <>
          <Route index element={<RedirectToHome />} />
          <Route path="/" element={<Layout />}>
            {createNestedRoutes(
              privateRoutes,
              Private,
              currentEmployee?.[0]?.user_details?.is_superuser
            )}
          </Route>
          <Route path="*" element={<RedirectToHome />} />
        </>
      ) : (
        <>
          <Route index element={<RedirectToLogin />} />
          {createNestedRoutes(
            publicRoutes,
            Public,
            currentEmployee?.[0]?.user_details?.is_superuser
          )}
          <Route path="*" element={<RedirectToLogin />} />
        </>
      )}
    </ReactRouterRoutes>
  );
};

export default Routes;
