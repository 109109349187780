import { Disclosure } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { MdKeyboardArrowUp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { sidebarMenus } from "../constants";
import { GRADIENT_BACKGROUND } from "../constants/classes";
import { logout } from "../modules/Authentication/features/authSlice";
import { setActivePage, setSidebarState } from "../store/commonSlice";
import { searchMenu } from "../utils";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const sideMenu = useRef(null);

  const { activePage, currentEmployee, sidebarEnabled } = useSelector(
    (state) => state.common
  );

  const [onSidebarHover, setOnSidebarHover] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);

  // useOutsideClick(sideMenu, () => setIsOpen(false));

  useEffect(() => {
    dispatch(setActivePage(searchMenu(sidebarMenus, location.pathname)?.key));
  }, [dispatch, location]);

  const handleMenu = (menu) => {
    if (menu.key === "profile") {
    } else if (menu?.key === "logout") {
      dispatch(logout());
    } else {
      navigate(menu.link);
      dispatch(setActivePage(menu.key));
    }
  };

  return (
    <>
      <nav
        className={`left-bar shadow-3xl transition-[width] duration-700 ease-in before:absolute before:border-8 before:border-transparent before:border-b-[#8c78d3ba] before:border-t-0 before:top-[-5px] before:left-9 before:-translate-x-1/2 ${GRADIENT_BACKGROUND} lg:min-w-20 md:mx-5 lg:my-0 lg:mx-5 lg:max-w-52 h-[calc(100vh-100px)]
      max-md:w-52 max-md:h-full max-md:top-0 max-md:overflow-y-scroll
      ${onSidebarHover ? "w-56" : ""} ${
          sidebarEnabled
            ? "lg-w-52 max-md:top-0  max-md:left-0 max-md:ml-0 max-md:-translate-x-56 max-w-3xl:-translate-x-56"
            : "w-20"
        } flex flex-col border-e-0 fixed rounded-md z-10`}
        onMouseLeave={() => setOnSidebarHover(false)}
      >
        <div className="container mx-auto px-3 max-w-full">
          <GrClose
            className="max-md:flex max-md:sticky max-md:left-[86%] max-md:top-3 max-md:fill-white hidden pointer"
            onClick={() => dispatch(setSidebarState(!sidebarEnabled))}
            stroke="#fff"
          />
          <div
            className="sd-body h-[calc(100vh-100px)] max-md:h-svh mx-0 my-2 flex flex-col justify-between"
            id="navbarSupportedContent"
          >
            {sidebarMenus?.map((m) => (
              <ul className="me-auto pb-0 mb-0 ps-0 w-full">
                {m?.children?.map((menu) => {
                  if (
                    menu?.permission === 1 &&
                    !currentEmployee?.[0]?.user_details?.is_superuser
                  ) {
                    return true;
                  }

                  return menu?.children?.length > 0 ? (
                    <Disclosure defaultOpen>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full justify-between text-slate-200 pl-px-4 py-2 text-left text-sm font-medium items-center">
                            <div className="flex items-center gap-2 pl-3">
                              <i
                                className={`text-2xl ${menu.icon} flex mb-0 items-center text-slate-200 `}
                                onMouseEnter={() => setOnSidebarHover(true)}
                              />
                              <p
                                className={`mb-0 uppercase ${
                                  !sidebarEnabled && !onSidebarHover
                                    ? "lg:hidden md:hidden"
                                    : "lg:flex md:flex"
                                }`}
                              >
                                {menu?.label}
                              </p>
                            </div>
                            {/* {sidebarEnabled  ? ( */}
                            <MdKeyboardArrowUp
                              size={25}
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } ml-1 text-2xl`}
                            />
                            {/* ) : null} */}
                          </Disclosure.Button>
                          <Disclosure.Panel className="pb-2 pl-4 text-sm text-gray-500">
                            <ul>
                              {menu?.children?.map((m, i) => (
                                <li
                                  key={i}
                                  className={`flex text-slate-200 no-underline uppercase cursor-pointer relative list-none p-2 pr-0 gap-2 rounded-lg font-medium text-xs items-center  ${
                                    activePage === m?.key
                                      ? "text-white  before:content before:absolute before:h-1.5 before:w-1.5 before:top-[42%] before:left-1 before:bg-white before:rounded-3xl"
                                      : ""
                                  }`}
                                  onClick={(e) => {
                                    handleMenu(m);
                                  }}
                                >
                                  <i
                                    className={`text-lg bx bx-desktop flex mb-0 items-center text-slate-200 ml-1 ${m?.icon}`}
                                    onMouseEnter={() => setOnSidebarHover(true)}
                                  />
                                  <p
                                    className={`mb-0 ${
                                      !sidebarEnabled && !onSidebarHover
                                        ? "lg:hidden md:hidden"
                                        : "lg:flex md:flex"
                                    } `}
                                  >
                                    {m?.label}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <li
                      key={menu.key}
                      className={`flex text-slate-200 no-underline uppercase cursor-pointer relative list-none p-3 pr-0 gap-2 rounded-lg font-normal text-sm ${
                        activePage === menu?.key
                          ? "text-white  before:content before:absolute before:h-1.5 before:w-1.5 before:top-[42%] before:left-1 before:bg-white before:rounded-3xl"
                          : ""
                      }`}
                      onClick={(e) => {
                        handleMenu(menu);
                        // navigate(menu.link);
                        // dispatch(setActivePage(menu.key));
                        // window.innerWidth < 991 &&
                        //   dispatch(setSidebarState(!sidebarEnabled));
                      }}
                    >
                      <div className="flex items-center gap-2">
                        <i
                          className={`text-2xl bx bx-desktop flex mb-0 items-center text-slate-200 ${menu.icon}`}
                          onMouseEnter={() => {
                            setOnSidebarHover(true);
                          }}
                        />
                        <p
                          className={`mb-0 ${
                            !sidebarEnabled && !onSidebarHover
                              ? "lg:hidden md:hidden"
                              : "lg:flex md:flex"
                          }  `}
                        >
                          {menu.label}
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ))}
            {/* <ul className="me-auto pb-0 mb-0 ps-0 overflow-y-scroll w-full ">
              {menuLabels?.map((menu) => (
                <li
                  key={menu.id}
                  className={`flex text-slate-200 no-underline uppercase cursor-pointer relative list-none p-3 pr-0 gap-2 rounded-lg font-normal text-sm ${
                    activePage == menu?.id
                      ? "text-white  before:content before:absolute before:h-1.5 before:w-1.5 before:top-[42%] before:left-1 before:bg-white before:rounded-3xl"
                      : ""
                  }`}
                  onClick={(e) => {
                    navigate(menu.link);
                    dispatch(setActivePage(menu.id));
                    window.innerWidth < 991 &&
                      dispatch(setSidebarState(!sidebarEnabled));
                  }}
                >
                  <div className="flex items-center gap-2">
                    <i
                      className={`text-2xl bx bx-desktop flex mb-0 items-center text-slate-200 ${menu.icon}`}
                      onMouseEnter={() => {
                        setOnSidebarHover(true);
                      }}
                    />
                    <p
                      className={`mb-0 ${
                        !sidebarEnabled && !onSidebarHover
                          ? "lg:hidden md:hidden"
                          : "lg:flex md:flex"
                      }  `}
                    >
                      {menu.label}
                    </p>
                  </div>
                </li>
              ))}
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between text-slate-200 pl-px-4 py-2 text-left text-sm font-medium items-center">
                      <div className="flex items-center gap-2 pl-3">
                        <i
                          className={`text-2xl bx bx-transfer flex mb-0 items-center text-slate-200 `}
                          onMouseEnter={() => setOnSidebarHover(true)}
                        />
                        <p
                          className={`mb-0 uppercase ${
                            !sidebarEnabled && !onSidebarHover
                              ? "lg:hidden md:hidden"
                              : "lg:flex md:flex"
                          }`}
                        >
                          file transfers
                        </p>
                      </div>
                      <MdKeyboardArrowUp
                        size={25}
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } ml-1 text-2xl`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 pl-4 text-sm text-gray-500">
                      <ul>
                        {FILE_TRANSFER_SUB_MENU?.map((m, i) => (
                          <li
                            key={i}
                            className={`flex text-slate-200 no-underline uppercase cursor-pointer relative list-none p-2 pr-0 gap-2 rounded-lg font-medium text-xs items-center  ${
                              activePage == m?.id
                                ? "text-white  before:content before:absolute before:h-1.5 before:w-1.5 before:top-[42%] before:left-1 before:bg-white before:rounded-3xl"
                                : ""
                            }`}
                            onClick={(e) => {
                              navigate(m.link);
                              dispatch(setActivePage(m.id));
                              window.innerWidth < 991 &&
                                dispatch(setSidebarState(!sidebarEnabled));
                              setIsOpen(false);
                            }}
                          >
                            <i
                              className={`text-lg bx bx-desktop flex mb-0 items-center text-slate-200 ml-1 ${m?.icon}`}
                              onMouseEnter={() => setOnSidebarHover(true)}
                            />
                            <p
                              className={`mb-0 ${
                                !sidebarEnabled && !onSidebarHover
                                  ? "lg:hidden md:hidden"
                                  : "lg:flex md:flex"
                              } `}
                            >
                              {m?.label}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </ul>
            <div className="">
              <ul>
                {ADMIN_MENU.slice(0, 2)?.map((menu, i) =>
                  menu.permission === 1 &&
                  currentEmployee?.[0]?.user_details?.is_superuser ? (
                    <li
                      key={i}
                      className={`flex text-slate-200 no-underline uppercase cursor-pointer relative list-none p-3 pr-0 gap-2 rounded-lg font-medium text-sm items-center ${
                        activePage == menu?.id
                          ? "text-white before:content before:absolute before:h-1.5 before:w-1.5 before:top-[42%] before:left-1 before:bg-white before:rounded-3xl"
                          : ""
                      }`}
                      onClick={(e) => {
                        if (menu?.id !== 9) {
                          navigate(menu.link);
                          dispatch(setActivePage(menu.id));
                          window.innerWidth < 991 &&
                            dispatch(setSidebarState(!sidebarEnabled));
                        } else {
                          dispatch(logout());
                        }
                      }}
                    >
                      <i
                        className={`text-2xl bx bx-desktop flex mb-0 items-center text-slate-200 ${menu.icon}`}
                      ></i>
                      <p
                        className={`mb-0 ${
                          !sidebarEnabled && !onSidebarHover
                            ? "lg:hidden md:hidden"
                            : "lg:flex md:flex"
                        } md:flex `}
                      >
                        {menu.label}
                      </p>
                    </li>
                  ) : null
                )}
                {ADMIN_MENU.slice(2)?.map((menu, i) => (
                  <li
                    key={i}
                    className={`flex text-slate-200 no-underline uppercase cursor-pointer relative list-none p-3 pr-0 gap-2 rounded-lg font-medium text-sm items-center ${
                      activePage == menu?.id
                        ? "text-white before:content before:absolute before:h-1.5 before:w-1.5 before:top-[42%] before:left-1 before:bg-white before:rounded-3xl"
                        : ""
                    }`}
                    onClick={(e) => {
                      if (menu?.id !== 9) {
                        navigate(menu.link);
                        dispatch(setActivePage(menu.id));
                        window.innerWidth < 991 &&
                          dispatch(setSidebarState(!sidebarEnabled));
                      } else {
                        dispatch(logout());
                      }
                    }}
                  >
                    <i
                      className={`text-2xl bx bx-desktop flex mb-0 items-center text-slate-200 ${menu.icon}`}
                    ></i>
                    <p
                      className={`mb-0 ${
                        !sidebarEnabled && !onSidebarHover
                          ? "lg:hidden md:hidden"
                          : "lg:flex md:flex"
                      }`}
                    >
                      {menu.label}
                    </p>
                  </li>
                ))}
              </ul>
            </div> */}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
