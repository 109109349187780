export const ErrorMessage = ({ errors, inputName }) => (
  <>
    {errors[inputName] && (
      <small className="text-sm text-red-600 font-medium block mt-1">
        {errors[inputName]["message"]
          ? errors[inputName]["message"]
          : errors[inputName]["type"] === "allowed"
          ? `invalid username`
          : `${inputName} is required`}
      </small>
    )}
  </>
);

export const defaultStyle = (themeMode, error = false) => {
  return {
    noOptionsMessage: (base) => ({
      ...base,
      padding: "10px 5px",
      background: "#1e293b",
    }),
    container: (base) => ({
      ...base,
      //   background: themeMode === 0 ? "#FFF" : "#1e1e2f",
      borderRadius: "8px",
      color: themeMode === 0 ? "#1e293b" : "#f9fafb",
      // paddingInline: 0,
      borderColor: error ? "#ff4343" : themeMode === 0 ? "#d1d5db" : "#dee2e6",
      // marginLeft: "10px",
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#ddd",
      color: "#8c78d3",
      borderRadius: "6px",
    }),
    MultiValueGeneric: (base) => ({
      ...base,
      color: "#d1d5db",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: error ? "#ff4343" : themeMode === 0 ? "#d1d5db" : "#4b5563",
      background: themeMode === 0 ? "#f9fafb" : "#1e293b",
      outline: "none",
      color: themeMode === 0 ? "#1e293b" : "#111827",
      padding: "2px 0",
      fontSize: "14px",
      borderRadius: "8px",
    }),
    valueContainer: (base) => ({
      ...base,
      color: themeMode === 0 ? "#1e293b" : "#111827",
      background: themeMode === 0 ? "#f9fafb" : "#1e293b",
      borderRadius: "6px",
    }),
    singleValue: (base) => ({
      ...base,
      color: themeMode === 0 ? "#1e293b" : "#f9fafb",
    }),
    option: (base) => ({
      ...base,
      background: themeMode === 0 ? "#f9fafb" : "#1e293b",
      color: themeMode === 0 ? "#1e293b" : "#f9fafb",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 20,
    }),
    menuList: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };
};

export const searchMenu = (menu = [], location) => {
  let currentMenu = null;
  menu?.some((obj) => {
    if (obj?.link === location) {
      currentMenu = obj;
      return true;
    }

    if (obj?.children?.length > 0) {
      currentMenu = searchMenu(obj?.children, location);
      return currentMenu !== null;
    }
    return false;
  });

  return currentMenu;
};
