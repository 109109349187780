// Authentication
export const LOGIN = "/auth/token/";

//  KPI Dashboard
export const GET_KPI_LIST = "/monitoring/uptime-logs/";
export const GET_MONITORING_PROJECT = "/monitoring/projects/";
export const GET_MONITORING_SITE = "/monitoring/kpi-sites/";
export const GET_MONITORING_OPERATIONAL = "/monitoring/operational/";

// Monitoring
export const GET_MONITORING_LOGS = "/monitoring/incident-durations/";
