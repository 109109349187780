export const sidebarMenus = [
  {
    position: "top",
    children: [
      {
        key: "dashboard",
        label: "dashboard",
        link: "",
        icon: "bx bxs-dashboard",
        children: [],
      },
      {
        key: "incident",
        label: "incident",
        link: "/incident",
        icon: "bx bxs-time",
        children: [],
      },
    ],
  },
  {
    position: "bottom",
    children: [
      {
        key: "logout",
        label: "Logout",
        link: "",
        icon: "bx bx-power-off",
        children: [],
      },
    ],
  },
];
